<template>
  <b-card bg-variant="light" no-body class="my-3 room-info" :class="page">

    <b-carousel
      v-model="slide"
      :interval="20000"
      indicators
      ref="rooms-carousel"
      class="banner-carousel"
      style="height:400px;"
      v-if="page==='rooms'"
    >
      <b-carousel-slide
        v-for="(src, index) in item.src"
        :img-src="src"
        :key="index"
        img-height="400"
      ></b-carousel-slide>
    </b-carousel>

    <b-card-img-lazy :src="item.src" top class="card-hotel-image" v-bind="mainProps" v-else></b-card-img-lazy>

    <b-card-body v-if="page === 'hotels'">
      <b-card-title><router-link :to="`/hotel-info/?city=${item.city}&hotelCode=${item.exCode}`">{{item.name}}</router-link></b-card-title>
      <b-card-text>
        <div v-html="item.description"></div>
        <!-- <Readmore :message="item.description" :line="10" :mobileShow="device === 'mobile'"/> -->
      </b-card-text>
    </b-card-body>

    <b-card-body v-else-if="page === 'rooms'">
      <b-card-title>{{item.name}}</b-card-title>
      <b-card-text>
        <div v-html="item.description"></div>
      </b-card-text>
    </b-card-body>

    <b-card-body v-else :title="item.description ? item.name : ''">
      <b-card-text>
        {{ item.description }}
      </b-card-text>
    </b-card-body>

</b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { BCard, BCardText, BCardImgLazy, BCardBody, BCardTitle, BCarousel, BCarouselSlide } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCardText,
    BCardImgLazy,
    BCardBody,
    BCardTitle,
    BCarousel,
    BCarouselSlide,
    // Readmore: () => import('@/components/productPage/atoms/Readmore'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    page: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slide: 0,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#ddd',
      },
    };
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
<style>
  .room-info .carousel-indicators {
    transform: rotate(0deg) translateY(-50%) scale(1, -1);
    width: 100%;
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    background: rgba(0,0,0,0.1);
    text-align: center;
    padding: 5px;
  }
  .room-info .carousel-indicators li{
    vertical-align: middle;
  }
  .room-info .carousel-inner{
    height: inherit;
  }
  .card.hotels .card-hotel-image {
    height: 300px;
  }
@media (min-width: 576px){
  .room-info .card-columns .card {
    height: 500px;
    overflow-y: auto;
  }
  .room-info .card-columns .card.hotels {
    height: 600px;
  }
}
</style>

<style scoped>
.no-body .card-body {
  padding: 0;
}
</style>
